<template>
  <div class="v-application--wrap">
    <base-layout-drawer />
    <base-layout-toolbar />
    <base-layout-content />
    <base-layout-footer />    
  </div>
</template>
<script>
import BaseLayoutDrawer from "./BaseLayoutDrawer.vue";
import BaseLayoutToolbar from "./BaseLayoutToolbar.vue";
import BaseLayoutContent from "./BaseLayoutContent.vue";
import BaseLayoutFooter from "./BaseLayoutFooter.vue";
export default {
  components: {
    BaseLayoutDrawer,
    BaseLayoutToolbar,
    BaseLayoutContent,
    BaseLayoutFooter,
  },
};
</script>