import Vue from "vue";
import Vuex from "vuex";
import packages from "./modules/packages";
import settings from "./modules/settings";
import layers from "./modules/layers";
import external_layers from "./modules/external_layers";
import features from "./modules/features";
import ui from "./modules/ui";
import search from "./modules/search";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    packages,
    layers,
    features,
    settings,
    ui,
    search,
    external_layers,
  },
  /**
   * Global store state, mutations, actions and getters
   */
  state: {},
  mutations: {},
  actions: {},
  getters: {},
});
