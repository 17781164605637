export default {
  base: {
    primary: "#2a3b50",
    accent: "#ff6b99",
    secondary: "#26ff8c",
    success: "#a5d64c",
    info: "#ff53d0",
    warning: "#ff8e00",
    error: "#ff5252",
  },
  light: {
    primary: "#2a3b50",
    accent: "#ff6b99",
    secondary: "#26ff8c",
    success: "#a5d64c",
    info: "#ff53d0",
    warning: "#ff8e00",
    error: "#ff5252",
  },
  dark: {
    primary: "#ffffff",
    accent: "#FF4081",
    secondary: "#21dc79",
    success: "#86af3f",
    info: "#f34fc6",
    warning: "#FB8C00",
    error: "#FF5252",
  },
};
