<template>
    <div class="fill-height">
        <v-card flat>
            <v-card outlined tile class="pa-0">
                <v-text-field tabindex="2" class="pa-2" v-model="search" :label="$t('global.search')" dense flat solo
                    hide-details clearable prepend-inner-icon="mdi-magnify" activatable
                    clear-icon="mdi-close-circle-outline"></v-text-field>
            </v-card>
            <v-card-text style="height: calc(100dvh - 250px); overflow-y: auto" class="pa-0">
                <v-treeview v-if="!!currentPackage" v-model="selectedItems" ref="tree" selectable :open-all="true"
                    selected-color="primary" item-disabled="_isLoading" :items="layers" item-key="id"
                    item-text="friendly_name" return-object multiple-active :search="search" :disabled="isAnythingLoading"
                    dense>
                    <template v-slot:prepend="{ item, open }">
                        <div class="d-flex flex-row align-center">
                            <v-icon v-if="hasChildren(item)">
                                {{ open ? "mdi-folder-open" : "mdi-folder" }}
                            </v-icon>
                            <v-icon v-else v-html="resolveCorrectIcon(item)"></v-icon>
                        </div>
                    </template>
                    <template v-slot:append="{ item, selected }">
                        <v-card v-if="!hasChildren(item) && selected" class="d-flex flex-row align-center" flat tile>
                            <v-card flat tile height="30" v-if="isLayerVectorial(item) && item?.layerdata"
                                class="px-1 d-flex align-center justify-center">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-chip color="primary" x-small class="ma-0 font-weight-bold" v-bind="attrs"
                                            v-on="on">{{ item.layerdata.features_total_count }}</v-chip>
                                    </template>
                                    <span>{{ item.layerdata.features_total_count }} {{ $t('global.elements') }}</span>
                                </v-tooltip>
                            </v-card>
                            <v-card outlined tile height="30" width="30" class="d-flex align-center justify-center">
                                <v-progress-circular v-if="item._isLoading" :size="20" indeterminate color="black"
                                    :width="2"></v-progress-circular>
                                <Legend v-else :layer="item" />
                            </v-card>
                            <v-card outlined tile height="30" v-if="isLayerVectorial(item)">
                                <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
                                    <template #activator="{ on: onMenu }">
                                        <v-tooltip bottom>
                                            <template #activator="{ on: onTooltip }">
                                                <v-btn v-on="{ ...onMenu, ...onTooltip }" icon text plain small
                                                    :disabled="loadingLayers">
                                                    <v-icon>mdi-cog-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Opções</span>
                                        </v-tooltip>
                                    </template>
                                    <LayerSettings v-model="item.layer.default_style_map_id" :layer="item"
                                        @refresh="handleLayerRefresh" />
                                </v-menu>
                            </v-card>
                            <v-card outlined tile height="30" width="30">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon text plain small v-bind="attrs" v-on="on"
                                            @click.stop="handleZoomToLayer(item)" :disabled="loadingLayers">
                                            <v-icon>mdi-magnify-plus-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('global.zoom_in') }}</span>
                                </v-tooltip>
                            </v-card>
                        </v-card>
                    </template>

                </v-treeview>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>

import { EventBus } from "@/helpers/event-bus";
import LayerSettings from "@/components/Layers/LayerSettings";
import Legend from '@/components/Layers/Legend';

export default {
    components: {
        LayerSettings,
        Legend,
    },
    data() {
        return {
            search: null,
        };
    },
    computed: {
        isAnythingLoading() {
            return this.$store.state.layers.loading;
        },
        currentPackage() {
            return this.$store.state.packages.currentPackage;
        },
        selectedItems: {
            get() {
                return this.$store.state.layers.activeLayers;
            },
            set(val) {
                this.$store.dispatch("layers/SET_ACTIVE_LAYERS", val);
            }
        },
        layers() {
            return this.$store.state.packages.currentPackage
                ? this.$store.state.packages.currentPackage.layers
                : [];
        },
        loadingLayers() {
            return this.$store.state.layers.loading;
        },
    },
    watch: {
        search() {
            this.$refs.tree.updateAll(true);
        },
    },
    methods: {

        hasChildren(layer) {
            return !!layer.children;
        },

        resolveCorrectIcon(item) {
            switch (item?.layer?.layertype?.geometry_type) {
                case "Point":
                    return "mdi-checkbox-blank-circle";
                case "PointCluster":
                    return "mdi-record-circle-outline";
                case "Polygon":
                    return "mdi-square";
                case "GeometryCollection":
                    return "mdi-source-fork";
                case "LineString":
                    return "mdi-minus";
                case "WMS":
                    return "mdi-image";
                default:
                    return "";
            }
        },

        handleLayerRefresh(layer) {
            EventBus.$emit("refreshLayer", layer);
        },

        handleZoomToLayer(layer) {
            EventBus.$emit("fitToBounds", layer);
        },

        isLayerVectorial(item) {
            return item?.layer?.layertype?.geometry_type !== 'WMS';
        }

    },
};
</script>
<style>
.v-treeview-node__root {
    min-height: 40px !important;
    max-height: 40px !important;
}

button+button.v-treeview-node__checkbox {
    display: none !important;
}
</style>