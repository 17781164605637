import { featuresService } from "@/api_services/features.service";
export default {
  namespaced: true,
  state: {
    loadingResults: false,
    loadingFeatureDetails: false,
    list: [],
    featureSelected: null,
    selectedFeatureDetails: null,
  },
  mutations: {
    setLoadingResults(state, loading) {
      state.loadingResults = loading;
    },

    setLoadingFeatureDetails(state, loading) {
      state.loadingFeatureDetails = loading;
    },

    setFeatures(state, list) {
      state.list = list;
    },

    setFeatureSelected(state, feature) {
      state.featureSelected = feature;
    },

    setFeatureDetails(state, details) {
      state.selectedFeatureDetails = details;
    },
  },
  actions: {
    SEARCH: (context, { package_id, term, bbox, active_layers }) => {
      return new Promise((resolve) => {
        if (!term || term === "") {
          resolve();
          return;
        }
        context.commit("setLoadingResults", true);
        featuresService
          .search(package_id, term, bbox, active_layers)
          .then(({ data }) => {
            context.commit("setFeatures", data);
            context.commit("setLoadingResults", false);
            resolve(data);
          })
          .catch(() => {
            context.commit("setFeatures", []);
            context.commit("setLoadingResults", false);
          });
      });
    },

    SET_FEATURE_SELECTED(context, feature) {
      context.commit("setFeatureSelected", feature);
      context.dispatch("ui/SET_FEATURE_DETAILS_OPEN", feature !== null, {
        root: true,
      });
      if (feature) {
        context.dispatch("GET_FEATURE_DETAILS", {
          package_id: context.rootState.ui.currentPackageId ?? null,
          feature_id: feature.id,
        });
      }
    },

    CLEAR_SEARCH_RESULTS(context) {
      context.commit("setFeatures", []);
    },

    GET_FEATURE_DETAILS: (context, { package_id, feature_id }) => {
      context.commit("setLoadingFeatureDetails", true);
      context.commit("setFeatureDetails", null);
      return new Promise((resolve) => {
        featuresService
          .getDetails(package_id, feature_id)
          .then(({ data }) => {
            context.commit("setLoadingFeatureDetails", false);
            context.commit("setFeatureDetails", data);
            resolve(data);
          })
          .catch(() => {
            context.commit("setFeatureDetails", null);
            context.commit("setLoadingFeatureDetails", false);
          });
      });
    },

    RESET_STATE: (context) => {
      context.commit("setFeatures", []);
      context.commit("setFeatureSelected", null);
      context.commit("setFeatureDetails", null);
    },
  },
};
