import configs from "@/helpers/configs";
import axios from "axios";

export const searchService = {
    searchByPostalcode,
    searchByAddress
};

async function searchByPostalcode(postalCode) {
    var requestOptions = {
        method: "GET",
        params: {
            json: 1
        },
    };
    const res = await axios.get(
        configs.getGeoapiUrl() + "/cp/" + postalCode + "?json=1",
        requestOptions
    );
    return res;
}

async function searchByAddress(address) {
    var requestOptions = {
        method: "GET",
        params: {
            q: address,
            format: "geojson",
            polygon_geojson: 1,
            addressdetails: 1,
            countrycodes: "pt"
        },
    };
    const res = await axios.get(
        configs.getNominatimUrl() + '/search',
        requestOptions
    );
    return res;
}
