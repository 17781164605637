export const svgUtils = {
    svgToPng,
    getSvgUrl,
    svgUrlToPng,
    svgToPng_v2
};
function svgToPng(svg, callback) {
    const url = this.getSvgUrl(svg);
    this.svgUrlToPng(url, (imgData) => {
        callback(imgData);
        URL.revokeObjectURL(url);
    });
}

function getSvgUrl(svg) {
    return URL.createObjectURL(new Blob([svg], { type: "image/svg+xml" }));
}
function svgUrlToPng(svgUrl, callback) {
    const svgImage = document.createElement("img");
    // imgPreview.style.position = 'absolute';
    // imgPreview.style.top = '-9999px';
    document.body.appendChild(svgImage);
    svgImage.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = svgImage.clientWidth;
        canvas.height = svgImage.clientHeight;
        const canvasCtx = canvas.getContext("2d");
        canvasCtx.drawImage(svgImage, 0, 0);
        const imgData = canvas.toDataURL("image/png");
        document.body.removeChild(svgImage);
        callback(imgData);
    };
    svgImage.src = svgUrl;
}


function svgToPng_v2(svg) {
    return new Promise(resolve => {
        const url = this.getSvgUrl(svg);
        const svgImage = document.createElement("img");
        document.body.appendChild(svgImage);
        svgImage.onload = function () {
            const canvas = document.createElement("canvas");
            canvas.width = svgImage.clientWidth;
            canvas.height = svgImage.clientHeight;
            const canvasCtx = canvas.getContext("2d");
            canvasCtx.drawImage(svgImage, 0, 0);
            const imgData = canvas.toDataURL("image/png");
            document.body.removeChild(svgImage);
            resolve(imgData);
        };
        svgImage.onerror = function () {
            resolve(null);
        };
        svgImage.src = url;
    });

}
