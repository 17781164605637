<template>
  <div>
    <v-dialog v-model="isOpen" persistent max-width="290">
      <v-card tile>
        <v-card-title>{{
          $t("global.delete_uploaded_layer")
        }}</v-card-title>
        <v-card-text>{{ $t("global.delete_uploaded_layer_confirmation") }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn color="darken-1" text @click="cancelDelete">{{ $t('global.no') }}</v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">{{ $t('global.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    isOpen: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    cancelDelete() {
      this.isOpen = false;
    },
    confirmDelete() {
      this.$emit('confirmDelete');
      this.isOpen = false;
    },
  },
};
</script>
