<template>
    <div class="fill-height">
        <v-tabs v-model="tabs" grow :show-arrows="true" height="40" active-class="selected-tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#server-layers" class="primary--text">
                <v-icon left>mdi-file-tree</v-icon>
                <div class="text--overline">{{$t('global.server_layers')}}</div>
            </v-tab>
            <v-tab href="#external-layers" class="primary--text">
                <v-icon left>mdi-format-list-bulleted</v-icon>
                <div class="text--overline">{{$t('global.external_layers')}}</div>
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tabs" class="fill-height">
            <v-tab-item value="server-layers" class="fill-height">
                <LayersTreeView></LayersTreeView>
            </v-tab-item>
            <v-tab-item value="external-layers" class="fill-height">
                <ExternalLayersList></ExternalLayersList>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import LayersTreeView from '@/components/Layers/LayersTreeView';
import ExternalLayersList from '@/components/ExternalLayers/ExternalLayersList';

export default {
    components: {
        LayersTreeView,
        ExternalLayersList,
    },
    data() {
        return {
            tabs: "server-layers",
        };
    },
};
</script>
<style scoped>
.selected-tab {
    font-weight: bold;
}
</style>
<style>
.v-slide-group__prev.v-slide-group__prev--disabled,
.v-slide-group__next.v-slide-group__next--disabled {
    display: none !important;
}
</style>