import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const layersService = {
  getLayer,
  getWmsLayerBBox,
};

async function getLayer(pid, lid, stid) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.get(
    !stid
      ? `${configs.getApiUrl()}/v1/packages/${pid}/layers/${lid}`
      : `${configs.getApiUrl()}/v1/packages/${pid}/layers/${lid}/${stid}`,
    requestOptions
  );
  return res;
}

async function getWmsLayerBBox(pid, lid) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.get(
    `${configs.getApiUrl()}/v1/packages/${pid}/layers/${lid}/bbox`,
    requestOptions
  );
  return res;
}
