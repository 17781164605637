<template>
    <div class="fill-height">
        <div class="fill-height" v-if="layers.length == 0">
            <v-layout align-center justify-center column fill-height style="
                  position: relative;
                  width: 100%;
                ">
                <v-flex row align-center>
                    <div class="text-subtitle-2 text-center px-4">
                        <div>{{ $t('global.upload_external_no_layers_added') }} <a
                                class="font-weight-bold text-decoration-underline" @click.stop="addExternalLayer">{{
                                    $t('global.here') }}</a></div>
                    </div>
                </v-flex>
            </v-layout>
        </div>
        <div v-else  style="height: calc(100dvh - 250px);">
            <Loading style="position: absolute" v-if="loading" />
            <div v-else class="fill-height">
                <div class="text-center mt-4">
                    <v-btn color="primary" text class="font-weight-bold" small @click.stop="addExternalLayer"><v-icon small>mdi-upload</v-icon>{{ $t('global.add_more_external_layers') }}</v-btn>
                </div>
                <v-list flat class="pa-0">
                    <v-list-item-group multiple color="primary">
                        <template>
                            <v-list-item v-for="(l, index) in layers" :key="index">
                                <template>
                                    <v-list-item-action class="ma-2">
                                        <v-checkbox v-model="l._isActive"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-bind="attrs" v-on="on" style="height: 25px"
                                                        class="d-flex align-center">
                                                        <span class="text-truncate">
                                                            {{ l.name }}
                                                        </span>
                                                    </div>
                                                </template>
                                                <div>
                                                    {{ l.name }}
                                                </div>
                                            </v-tooltip>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-card class="d-flex flex-row align-center pr-2" flat tile>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip color="primary" x-small class="ma-0 font-weight-bold" v-bind="attrs"
                                                    v-on="on">{{ l.geojson?.features?.length
                                                    }}</v-chip>
                                            </template>
                                            <span>{{ l.geojson?.features?.length }} {{ $t('global.elements')
                                            }}</span>
                                        </v-tooltip>
                                    </v-card>
                                    <v-card class="d-flex flex-row align-center" flat tile>
                                        <v-card height="36" width="36" outlined tile>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon text plain v-bind="attrs" v-on="on"
                                                        @click.stop="handleOpenEditStyleDialog(l)">
                                                        <v-icon>mdi-palette</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('global.change_style') }}</span>
                                            </v-tooltip>
                                        </v-card>
                                        <v-card height="36" width="36" outlined tile>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon text plain v-bind="attrs" v-on="on"
                                                        @click.stop="handleFocusOnLayer(l)">
                                                        <v-icon>mdi-magnify-plus-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('global.zoom_in') }}</span>
                                            </v-tooltip>
                                        </v-card>
                                        <v-card height="36" width="36" outlined tile>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon text plain v-bind="attrs" v-on="on"
                                                        @click.stop="handleLayerRemove(l)">
                                                        <v-icon>mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ $t('global.remove') }}</span>
                                            </v-tooltip>
                                        </v-card>
                                    </v-card>
                                </template>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
            </div>
        </div>
        <EditUploadedLayerStyle v-model="openEditLayerStyleDialog" :item="selectedLayer" />
        <DeleteDialog v-model="openDeleteLayerDialog" @confirmDelete="handleConfirmDelete" />
    </div>
</template>
<script>
import Loading from "@/components/General/Loading";
import DeleteDialog from "@/components/General/DeleteDialog";
import EditUploadedLayerStyle from "@/components/ExternalLayers/EditUploadedLayerStyle";

// Shared event bus
import { EventBus } from "@/helpers/event-bus";

export default {
    components: { Loading, DeleteDialog, EditUploadedLayerStyle },
    data() {
        return {
            openDeleteLayerDialog: false,
            openEditLayerStyleDialog: false,
            selectedLayer: null,
        };
    },
    computed: {
        layers() {
            return this.$store.state.external_layers.layers;
        },
        loading() {
            return this.$store.state.external_layers.loading;
        },
    },
    methods: {
        addExternalLayer() {
            this.$store.dispatch("ui/OPEN_UPLOAD_EXTERNAL_LAYER");
        },

        handleOpenEditStyleDialog(layer) {
            this.selectedLayer = layer;
            this.openEditLayerStyleDialog = true;
        },

        handleFocusOnLayer(layer) {
            EventBus.$emit("focusOnUploadedLayer", layer);
        },

        handleLayerRemove(layer) {
            this.selectedLayer = layer;
            this.openDeleteLayerDialog = true;
        },

        handleConfirmDelete() {
            if (this.selectedLayer) {
                this.$store.dispatch("external_layers/REMOVE_LAYER", this.selectedLayer);
            }
            this.selectedLayer = null;
        },
    },
};
</script>