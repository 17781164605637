<template>
  <v-app-bar dense app flat outlined class="app-bar" color="primary" dark>
    <v-app-bar-nav-icon @click.stop="toggleNavbar">
      <v-icon>mdi-menu</v-icon>
    </v-app-bar-nav-icon>
    <span class="text-overline font-weight-bold overflow-hidden" style="line-height: 1rem;" v-if="appName">{{
      appName
    }}</span>
    <span class="text-overline font-weight-bold" v-else
      ><v-progress-circular
        indeterminate
        :size="15"
        :width="1"
      ></v-progress-circular>
      a carregar...</span
    >
    <v-spacer />
    <v-btn small text fab class="mr-2" @click.stop="goHome" title="Início">
      <v-icon small>mdi-home</v-icon>
    </v-btn>
    <v-btn
      small
      text
      fab
      class="mr-2"
      :title="$t('global.help')"
      @click.stop="openHelp"
    >
      <v-icon small>mdi-information-outline</v-icon>
    </v-btn>
    
  </v-app-bar>
</template>
<script>
export default {  
  computed: {
    helpOpen: {
      get() {
        return this.$store.state.ui.helpDialogOpen;
      },
      set(val) {
        this.$store.dispatch("ui/SET_HELP_DIALOG_OPEN", val);
      },
    },
    appName() {
      return this.$store.state.packages.currentPackage
        ? this.$store.state.packages.currentPackage.name
        : null;
    },
  },
  methods: {
    toggleNavbar() {
      this.$store.dispatch("ui/TOGGLE_NAVBAR");
    },
    goHome() {
      this.$router.push("/");
    },
    openHelp() {
      this.helpOpen = true;
    },
  },
};
</script>

<style scoped>
.app-bar {
  background-color: white !important;
}
</style>
