
import baseTheme from '@/plugins/theme';

export default {
  install(Vue) {
    Vue.prototype.$setTheme = function (theme) {
      const dark = theme.dark;
      const light = theme.light;
      // set themes
      Object.keys(dark).forEach((i) => {
        this.$vuetify.theme.themes.dark[i] = dark[i];
      });
      Object.keys(light).forEach((i) => {
        this.$vuetify.theme.themes.light[i] = light[i];
      });
    };
    Vue.prototype.$setBaseTheme = function () {
      this.$setTheme(baseTheme);
    };
  },
};