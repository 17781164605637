<template>
  <div class="d-flex flex-row align-center">
    <span style="width: 300px" class="body-2">{{ label }}</span>
    <v-text-field :class="$vuetify.breakpoint.mdAndUp ? 'px-8' : ''" v-model="colorValue" outlined dense hide-details
      single-line readonly @click.stop="show">
      <template v-slot:prepend-inner>
        <v-menu v-model="menu" nudge-bottom="35" :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-card v-on="on" flat height="20" width="20" :color="colorValue"></v-card>
          </template>
          <v-card>
            <v-card-text>
              <v-color-picker v-model="colorValue" flat />
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: ["value", "label"],
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    colorValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    show() {
      this.menu = true;
    },
  },
};
</script>
