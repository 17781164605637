import configs from "@/helpers/configs";
import axios from "axios";
import {
    sharedHelpers
} from "@/api_services/utils/shared-helpers";

export const settingsService = {
    getSettings,
};

async function getSettings() {
    var requestOptions = {
        method: "GET",
        headers: sharedHelpers.getRequestHeaders(),
        params: {},
    };
    const res = await axios.get(
        configs.getApiUrl() + "/v1/settings",
        requestOptions
    );
    return res;
}
