<template>
  <v-card v-if="!!layer" max-width="300">
    <v-card-title
      >{{ layer.friendly_name }}
      <v-spacer />
      <v-chip x-small label outlined color="primary" class="ml-2 pa-2">{{
        layer.layer.layertype.name
      }}</v-chip></v-card-title
    >
    <v-divider />
    <v-card-text class="ma-0 py-1">
      <v-radio-group class="pt-2 ma-0" v-model="selectedThematicMap" dense>
        <template v-slot:label><div>Mapa temático ativo</div></template>
        <v-radio :value="null" @click.stop="refreshLayer">
          <template v-slot:label>
            <div class="thematic-map-label">
              Nenhum (aplicar estilo por omissão)
            </div>
          </template>
        </v-radio>
        <v-radio
          dense
          v-for="st in layer.layer.stylemaps"
          :key="st.id"
          :value="st.id"
          @click.stop="refreshLayer"
        >
          <template v-slot:label>
            <div class="thematic-map-label">
              {{ st.name }}
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-btn
        :disabled="loadingLayers"
        @click.stop="refreshLayer"
        x-small
        text
        plain
        color="primary"
        ><v-icon x-small>mdi-refresh</v-icon>Recarregar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: ["value", "layer"],
  computed: {
    loadingLayers() {
      return this.$store.state.layers.loading;
    },
    selectedThematicMap: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    refreshLayer() {
      this.$emit("refresh", this.layer);
    },
  },
};
</script>
<style scoped>
.thematic-map-label {
  font-size: 0.75rem;
}
</style>