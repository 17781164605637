<template>
  <v-main>
    <transition name="fade-transform" mode="out-in">
      <v-container class="fill-height pa-0 ma-0" fluid>
        <v-layout column>
          <v-flex xs12>
            <router-view />
          </v-flex>
        </v-layout>
      </v-container>
    </transition>
  </v-main>
</template>

<script>
export default {
  name: "BaseLayoutContent",
};
</script>
