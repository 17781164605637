import { layersService } from "@/api_services/layers.service";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    activeLayers: [],
    loading: false,
  },
  mutations: {
    setActiveLayers(state, layers) {
      state.activeLayers = layers;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    SET_ACTIVE_LAYERS: (context, layers) => {
      context.commit("setActiveLayers", layers);
    },

    GET_LAYER: (context, { package_id, layer_id, style_map_id, layer }) => {
      return new Promise((resolve, reject) => {
        if (layer) {
          Vue.set(layer, "_isLoading", true);
        }
        context.commit("setLoading", true);
        layersService
          .getLayer(package_id, layer_id, style_map_id)
          .then(({ data }) => {
            if (layer) {
              Vue.set(layer, "_isLoading", false);
              Vue.set(layer, "layerdata", data);
            }
            context.commit("setLoading", false);
            resolve(data);
          })
          .catch((ex) => {
            if (layer) {
              Vue.set(layer, "_isLoading", false);
            }
            context.commit("setLoading", false);
            reject(ex);
          });
      });
    },

    GET_WMS_BBOX: (context, { package_id, layer_id, layer }) => {
      return new Promise((resolve, reject) => {
        if (layer) {
          Vue.set(layer, "_isLoading", true);
        }
        layersService
          .getWmsLayerBBox(package_id, layer_id)
          .then(({ data }) => {
            if (layer) {
              Vue.set(layer, "_isLoading", false);
            }
            resolve(data);
          })
          .catch((ex) => {
            if (layer) {
              Vue.set(layer, "_isLoading", false);
            }
            reject(ex);
          });
      });
    },

    RESET_STATE(context) {
      context.commit("setActiveLayers", []);
      context.commit("setLoading", false);
    },
  },
};
