// Router
import router from "@/router";

import { packagesService } from "@/api_services/packages.service";
export default {
  namespaced: true,
  state: {
    loading: true,
    loadingPackage: false,
    list: [],
    currentPackage: null,
  },
  getters: {},
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setLoadingPackage(state, loading) {
      state.loadingPackage = loading;
    },

    setPackages(state, list) {
      state.list = list;
    },

    setCurrentPackage(state, pack) {
      state.currentPackage = pack;
    },
  },
  actions: {
    GET_PACKAGES: (context) => {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true);
        context.commit("setCurrentPackage", null);
        packagesService
          .getPackages()
          .then(({ data }) => {
            context.commit("setPackages", data);
            context.commit("setLoading", false);
            context.commit("setCurrentPackage", null);
            context.dispatch("ui/RESET_STATE", {}, { root: true });
            context.dispatch("features/RESET_STATE", {}, { root: true });
            context.dispatch("layers/RESET_STATE", {}, { root: true });
            context.dispatch("external_layers/RESET_STATE", {}, { root: true });
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },

    GET_PACKAGE: (context, id) => {
      return new Promise((resolve) => {
        context.dispatch("ui/RESET_STATE", {}, { root: true });
        context.dispatch("features/RESET_STATE", {}, { root: true });
        context.dispatch("layers/RESET_STATE", {}, { root: true });

        context.commit("setLoadingPackage", true);
        context.commit("setCurrentPackage", null);
        packagesService
          .getPackage(id)
          .then(({ data }) => {
            context.commit("setCurrentPackage", data);
            context.commit("setLoadingPackage", false);

            // Traverse data.layers to search for already active layers
            var layers = [];
            for (const layer of data.layers) {
              if (layer.children) {
                layers = layers.concat(layer.children);
              }
            }
            layers = layers.filter((l) => {
              return l.active;
            });
            context.dispatch("layers/SET_ACTIVE_LAYERS", layers, {
              root: true,
            });
            resolve(data);
          })
          .catch((e) => {
            console.warn(e);
            router.push("/error");
            context.dispatch("ui/RESET_STATE", {}, { root: true });
            context.dispatch("features/RESET_STATE", {}, { root: true });
            context.dispatch("layers/RESET_STATE", {}, { root: true });
            context.dispatch("external_layers/RESET_STATE", {}, { root: true });
          });
      });
    },
  },
};
