import BaseLayout from "@/views/layout/BaseLayout.vue";
import LandingLayout from "@/views/layout/LandingLayout.vue";

export const routes = [{
        path: "/",
        redirect: "/home",
        hidden: true,
    },
    {
        path: "/",
        component: LandingLayout,
        children: [{
            path: "/home",
            alias: "/landing",
            component: () => import("@/views/pages/Home"),
            name: "home",
            meta: {
                title: "route.home",
                icon: "mdi-home",
                noCache: true,
                pageCode: "home",
                alwaysShow: true,
            },
        }],
    },
    {
        path: "/",
        redirect: "/main",
        hidden: true,
    },
    {
        path: "/",
        component: BaseLayout,
        children: [{
            path: "/app/:id",
            alias: "/main",
            component: () => import("@/views/pages/Main"),
            name: "app",
            meta: {
                title: "route.main",
                icon: "mdi-map",
                noCache: true,
                pageCode: "app",
                alwaysShow: true,
            },
        }, ],
    },

    {
        path: "/error",
        component: () => import("@/views/pages/Error"),
        hidden: true,
    },
    {
        path: "*",
        redirect: "/error",
        hidden: true,
    },
];