
export const sharedHelpers = {
  getAuthToken,
  setStorageItemWithExpiry,
  getStorageItemWithExpiry,
  getRequestHeaders,
};

function getRequestHeaders() {
  let headers = {};
  // Auth token
  let authToken = getAuthToken();
  if (authToken) {
    headers["Authorization"] = "Bearer " + authToken;
  }
  headers["Content-Type"] = "application/json";
  return headers;
}

function getAuthToken() {
  let account = JSON.parse(getStorageItemWithExpiry("account"));
  return (account && account.access_token) ? account.access_token : null;
}

//Functions accessed by this module
function setStorageItemWithExpiry(key, value, ttl) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

function getStorageItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}