<template>
  <v-navigation-drawer
    v-model="isOpen"
    fixed
    app
    hide-overlay
    class="drawer"
    width="450"
  >
    <v-card
      tile
      flat
      class="fill-height overflow-y-hidden"
      style="position: relative"
    >
      <v-toolbar color="primary" dense dark flat tile>
        <v-toolbar-title>
          <span class="text-h6"><v-icon left>mdi-layers</v-icon>{{ $t('global.layers') }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.stop="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0 fill-height overflow-y-hidden">
        <Loading v-if="loadingPackage" />
        <LayersPanel v-else />
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <div style="height: 100px">
        <v-card flat tile width="100%" class="fill-height text-center">
          <v-divider />
          <v-card-text>
            <p>
              {{ new Date().getFullYear() }} —
              <strong>© {{ $t('global.company') }}</strong>
            </p>
            <p>{{ $t('global.all_rights_reserved') }}</p>
          </v-card-text>
        </v-card>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
import Loading from "@/components/General/Loading";
import LayersPanel from "@/components/Layers/LayersPanel";

export default {
  components: {
    Loading,
    LayersPanel,
  },
  computed: {
    isOpen: {
      get() {
        return this.$store.state.ui.navbarOpen;
      },
      set(val) {
        this.$store.dispatch("ui/SET_NAVBAR_OPEN", val);
      },
    },
    loadingPackage() {
      return this.$store.state.packages.loadingPackage;
    },
  },
};
</script>