import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import ThemeSelector from "./plugins/themeselector";
import axios from "axios";
import { VueMaskDirective } from 'v-mask';

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = true;

// Store
import store from "@/store";

// Router
import router from "@/router";

//Locale
import i18n from "@/locale";

//intercept request and add a header for shareablekey
axios.interceptors.request.use(
  function (config) {
    const shareableKey = store.state.settings.shareableKey;
    config.headers["X-Shareable-Key"] = shareableKey;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      router.push("/home");
    }
    return Promise.reject(error);
  }
);

Vue.use(ThemeSelector);

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
