import Vue from "vue";
import VueRouter from "vue-router";
import { routes } from "@/router/routes";
import store from "@/store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
NProgress.configure({}); // NProgress Configuration

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
});

/**
 * This is the auth guard to protect routes from access
 */
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  if (to.name === "app") {
    const key = to.query.key;
    await store.dispatch("settings/SET_SHAREABLE_KEY", key);
  }
  next();
});

router.afterEach(async () => {
  NProgress.done();
});

// Ignore navigation duplicate for all requests

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

export default router;
