<template>
  <div
    class="fill-height"
    v-if="
      layertype != 'Point' &&
      layertype != 'GeometryCollection' &&
      layertype != 'PointCluster'
    "
  >
    <svg
      viewBox="0 0 40 40"
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <g>
        <line
          v-if="layertype == 'LineString'"
          y2="7.85625"
          x2="5.73503"
          y1="35.25253"
          x1="33.13131"
          :stroke-dasharray="lineStrokeStyles.strokeDashArray"
          :stroke-linecap="lineStrokeStyles.strokeDashLineCap"
          :stroke="stroke"
          :stoke-opacity="strokeOpacity"
          :stroke-width="finalStyle.weight"
        />
        <rect
          v-else-if="layertype == 'Polygon'"
          height="25"
          width="25"
          y="7.87879"
          x="8.08081"
          :stroke="stroke"
          :stoke-opacity="strokeOpacity"
          :fill="fill"
          :fill-opacity="fillOpacity"
        />
        <g v-else-if="layertype == 'WMS'">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path
              d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M13.96,12.29L11.21,15.83L9.25,13.47L6.5,17H17.5L13.96,12.29Z"
            />
          </svg>
        </g>
      </g>
    </svg>
  </div>
  <div v-else class="fill-height d-flex align-center justify-center">
    <v-img :src="imgSrc" contain height="24" width="24"></v-img>
  </div>
</template>
<script>
import CustomMapMarkerSVG from "@/helpers/CustomMapMarkers/CustomMapMarkerSVG";
import { svgUtils } from "@/helpers/svg-utils";

export default {
  props: ["layerstyle", "layertype"],
  data() {
    return {
      finalStyle: null,
      imgSrc: null,
      urls: [],
      lineStrokeStyles: null,
    };
  },
  computed: {
    strokeLink() {
      return this.layerstyle.color_link;
    },
    strokeOpacityLink() {
      return this.layerstyle.opacity_link;
    },
    strokeNode() {
      return this.layerstyle.color_node;
    },
    strokeOpacityNode() {
      return this.layerstyle.opacity_node;
    },
    fillNode() {
      return this.layerstyle.fillColor_node;
    },
    fillOpacityNode() {
      return this.layerstyle.fillOpacity_node;
    },
    stroke() {
      return this.layerstyle?.color;
    },
    strokeOpacity() {
      return this.layerstyle?.opacity;
    },
    fill() {
      return this.layerstyle?.fillColor;
    },
    fillOpacity() {
      return this.layerstyle?.fillOpacity;
    },
  },
  async created() {
    this.finalStyle = Object.assign({}, this.layerstyle);
    let svg;
    if (this.layertype == "GeometryCollection") {
      if (this.finalStyle.custom_image) {
        let res = CustomMapMarkerSVG.buildNodeSvgPreview(this.finalStyle, null);
        let { unhoveredSVG } = res;
        svg = unhoveredSVG;
        svgUtils.svgToPng(svg, (imgData) => {
          this.imgSrc = imgData;
        });
      } else {
        let rawSvgText = null;
        if (
          this.finalStyle.iconShape_node != "rectangle-dot" ||
          this.finalStyle.iconShape_node != "doughnut"
        ) {
          let iconFromStyle = this.finalStyle.icon;
          let icon = iconFromStyle.split(" ")[1].replace("fa-", "");
          let r1 = await CustomMapMarkerSVG.fetchRawSvgFaIcon(
            `FontAwesome/${icon}.svg`
          );
          rawSvgText = r1;
        }
        let res = CustomMapMarkerSVG.buildNodeSvgPreview(
          this.finalStyle,
          rawSvgText
        );
        let { unhoveredSVG } = res;
        svg = unhoveredSVG;
        svgUtils.svgToPng(svg, (imgData) => {
          this.imgSrc = imgData;
        });
      }
    } else if (this.layertype == "Point" || this.layertype == "PointCluster") {
      if (this.finalStyle.custom_image) {
        let res = CustomMapMarkerSVG.buildSvg(this.finalStyle, null);
        let { unhoveredSVG } = res;
        svg = unhoveredSVG;
        svgUtils.svgToPng(svg, (imgData) => {
          this.imgSrc = imgData;
        });
      } else {
        let rawSvgText = null;
        if (
          this.finalStyle.iconShape === "marker" ||
          this.finalStyle.iconShape === "circle"
        ) {
          let iconFromStyle = this.finalStyle.icon;
          let icon = iconFromStyle.split(" ")[1].replace("fa-", "");
          let r1 = await CustomMapMarkerSVG.fetchRawSvgFaIcon(
            `FontAwesome/${icon}.svg`
          );
          rawSvgText = r1;
        }

        let res = CustomMapMarkerSVG.buildSvg(this.finalStyle, rawSvgText);
        let { unhoveredSVG } = res;
        svg = unhoveredSVG;
        svgUtils.svgToPng(unhoveredSVG, (imgData) => {
          this.imgSrc = imgData;
        });
      }
    } else if (this.layertype == "LineString") {
      this.lineStrokeStyles = CustomMapMarkerSVG._makeDashedPropLineString(
        this.finalStyle
      );
    }
  },
  methods: {},
};
</script>