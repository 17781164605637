<template>
  <v-footer v-if="links.length > 0" class="text-center d-flex flex-column" color="primary" dark tile app elevation="0"
    padless>
    <div>
      <v-btn v-for="link in links" :key="link.description" :href="link.link" target="_blank" small text>{{
        link.description }}</v-btn>
    </div>
  </v-footer>
</template>
<script>
export default {
  computed: {
    currentPackage() {
      return this.$store.state.packages.currentPackage;
    },
    links() {
      let urls = this?.currentPackage?.settings?.footer_url_links;
      if (urls) {
        try {
          let footerUrlLinks = JSON.parse(
            urls
          );
          return footerUrlLinks.filter((l) => l.link && l.description);
        } catch (_) {
           //eslint-ignore
        }
      }
      return [];
    },
  },
};
</script>
