export default {
  getApiUrl() {
    return process.env.VUE_APP_API_URL;
  },
  getGeoapiUrl(){
    return process.env.VUE_APP_GEOAPI_URL;
  },
  getNominatimUrl(){
    return process.env.VUE_APP_NOMINATIM_URL;
  },
  getOgrApiUrl() {
    return process.env.VUE_APP_OGR_URL;
  },
};
