<template>
  <v-dialog v-model="isOpen" :width="$vuetify.breakpoint.mobile ? '80%' : '60%'" max-width="800" persistent>
    <v-card tile>
      <v-toolbar color="primary" flat dark>
        <v-toolbar-title>
          <span>
            <v-icon left>mdi-palette</v-icon>{{ $t('global.change_style') }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 50vh; overflow-y: auto">
        <v-row class="pa-6">
          <v-col cols="12" md="6">
            <div>
              <IncrementInput :label="$t('global.line_width')" :min="1" :max="20" :incrementBy="1" :maxlength="2" 
                v-model="style.lineWidth" />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <IncrementInput :label="$t('global.line_opacity')" min="5" max="100" incrementBy="5" maxlength="3"
                v-model="style.lineOpacity" />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <IncrementInput :label="$t('global.point_radius')" min="1" max="20" incrementBy="1" maxlength="2"
                v-model="style.pointRadius" />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <IncrementInput :label="$t('global.fill_opacity')" min="0" max="100" incrementBy="5" maxlength="3"
                v-model="style.fillOpacity" />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <ColorPicker :label="$t('global.fill_color')" v-model="style.fillColor" />
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <ColorPicker :label="$t('global.line_color')" v-model="style.lineColor" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed @click.stop="close">
          <v-icon left>mdi-close-circle-outline</v-icon>{{ $t("global.cancel") }}
        </v-btn>
        <v-btn color="primary" depressed @click.stop="changeStyle">
          <v-icon left>mdi-content-save</v-icon>{{ $t("global.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import IncrementInput from "@/components/ExternalLayers/IncrementInput";
import ColorPicker from "@/components/ExternalLayers/ColorPicker";
import { EXTERNAL_LAYERS_BASE_STYLE } from '@/helpers/external-layers-base-style';

export default {
  props: ["value", "item"],
  components: {
    IncrementInput,
    ColorPicker,
  },
  data() {
    return {
      style: EXTERNAL_LAYERS_BASE_STYLE,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    isOpen(val) {
      if (val && this.item) {
        let styleCopy = {
          lineWidth: this.item.style.lineWidth,
          pointRadius: this.item.style.pointRadius,
          lineOpacity: this.item.style.lineOpacity,
          fillOpacity: this.item.style.fillOpacity,
          fillColor: this.item.style.fillColor,
          lineColor: this.item.style.lineColor
        }
        this.style = Object.assign({}, styleCopy);
      }
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
    changeStyle() {
      this.$set(this.item, 'style', this.style);
      this.close();
    }
  }
};
</script>
