<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    settingsInfo() {
      return this.$store.state.settings.settingsInfo;
    },
  },
  watch: {
    settingsInfo() {
      if (this.settingsInfo && this.settingsInfo.platform_project_title) {
        window.document.title = this.settingsInfo.platform_project_title;
        const favicons = document.getElementsByClassName("favicon");
        if (favicons) {
          for (let i = 0; i < favicons.length; i++) {
            let favicon = favicons[i];
            favicon.href = this.settingsInfo.platform_project_icon;
          }
        }
      }
    },
  },
};
</script>

<style>
html,
body {
  overflow: hidden !important;
}
.bg-primary {
  background-color: var(--v-primary-base) !important;
}

.v-application--wrap {
  min-height: 100dvh; /* fix the  dynamic viewport */
}
</style>
