import { searchService } from "@/api_services/search.service";

export default {
  namespaced: true,
  state: {
    loadingByPostalCode: false,
    loadingByAddress: false,
  },
  mutations: {
    setLoadingByPostalCode(state, isLoading) {
      state.loadingByPostalCode = isLoading;
    },

    setLoadingByAddress(state, isLoading) {
      state.loadingByAddress = isLoading;
    },
  },
  actions: {
    async GET_BY_POSTAL_CODE(context, postalCode) {
      context.commit("setLoadingByPostalCode", true);
      let result = null;
      let error = null;
      try {
        let res = await searchService.searchByPostalcode(postalCode);
        if (res.status == 200 && res.data.pontos) {
          let points = Object.values(
            res.data.pontos
              .map((p) => {
                return {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [p.coordenadas[1], p.coordenadas[0]],
                  },
                  properties: {
                    id: p.id,
                    street: p.rua,
                    house: p.casa,
                    description: `${p.rua} - ${p.casa}`,
                  },
                };
              })
              .reduce((acc, f) => {
                if (!acc[f.properties.casa]) {
                  acc[f.properties.house] = f;
                }
                return acc;
              }, {})
          );
          let polygonExtent = null;
          if (res.data.poligono) {
            polygonExtent = {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [res.data.poligono.map((c) => [c[1], c[0]])], // we needed to invert this
              },
              properties: {
                municipality: res.data.Concelho,
                locality: res.data.Localidade,
                streets: res.data.ruas,
                description: `${res.data.Localidade} - ${res.data.ruas.join(
                  "\n"
                )}`,
              },
            };
          }
          context.commit("setLoadingByPostalCode", false);
          result = {
            points: {
              type: "FeatureCollection",
              features: points,
            },
            polygon: polygonExtent,
          };
        }
      } catch (e) {
        error = e;
        context.commit("setLoadingByPostalCode", false);
      }
      return new Promise((resolve, reject) => {
        if (!error) resolve(result);
        else reject(error);
      });
    },

    async GET_BY_ADDRESS(context, address) {
      context.commit("setLoadingByAddress", true);
      let result = null;
      let error = null;
      try {
        let res = await searchService.searchByAddress(address);
        if (res.status == 200) {
          context.commit("setLoadingByAddress", false);
          result = res.data;
        } else {
          context.commit("setLoadingByAddress", false);
        }
      } catch (e) {
        error = e;
        context.commit("setLoadingByAddress", false);
      }
      return new Promise((resolve, reject) => {
        if (!error) resolve(result);
        else reject(error);
      });
    },
  },
};
