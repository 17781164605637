export default {
  namespaced: true,
  state: {
    layers: [],
    loading: false,
  },
  mutations: {
    addLayer(state, layer) {
      state.layers.push(layer);
    },
    removeLayer(state, layer) {
      let foundIdx = state.layers.findIndex((el) => el.id == layer.id);
      if (foundIdx !== -1) {
        state.layers.splice(foundIdx, 1);
      }
    },
    setLayers(state, layers) {
      state.layers = layers;
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    RESET_STATE(context) {
      context.commit("setLayers", []);
    },

    ADD_LAYER(context, layer) {
      // add a unique id
      if (typeof crypto !== "undefined") {
        layer.id = crypto.randomUUID();
      }
      context.commit("addLayer", layer);
    },

    REMOVE_LAYER(context, layer) {
      context.commit("removeLayer", layer);
    },

    SET_UPLOAD_EXTERNAL_LAYER_LOADING(context, isLoading) {
      context.commit("setLoading", isLoading);
    },
  },
};
