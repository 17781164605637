<template>
  <v-menu :close-on-content-click="false" offset-overflow>
    <template v-slot:activator="{ on, attrs }">
      <div class="fill-height" v-bind="attrs" v-on="on">
        <StylePreview :layertype="layer.layer.layertype.geometry_type" :layerstyle="layer.layer.style" />
      </div>
    </template>
    <v-card width="300" style="position: relative;">
      <v-card-title>{{ layer.friendly_name }}
        <v-spacer />
        <v-chip x-small label outlined color="primary" class="ml-2 pa-2">{{
          layerName
        }}</v-chip></v-card-title>
      <v-divider />
      <v-card-text style="overflow-y: auto; max-height: 500px; min-height: 40px;">
        <div v-if="hasStyleMapsApplied">
          <div class="subtitle-2">{{ $t('global.style_map_description') }}</div>
          <div>
            <div v-for="sc in layer.layerdata.style_classes" :key="sc.id"
              class="d-flex flex-row align-center justify-space-between my-1">
              <div class="text-caption">{{ sc.name }}</div>
              <div class="style-preview">
                <StylePreview :layertype="layer.layer.layertype.geometry_type" :layerstyle="sc.style" />
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="layer.layer.layertype.geometry_type == 'WMS'">
          <v-container class="pa-0 ma-0 mt-5" fluid>
            <v-row v-if="showLegend">
              <v-col cols="12" class="py-0">
                <div class="text-caption">{{ $t('global.legend') }}:</div>
                <a target="_blank" :href="getWMSLegendImageUrl(layer)" title="Ver legenda">
                  <v-img class="mx-auto mb-4" contain min-height="50" max-height="200" max-width="100%"
                    lazy-src="@/assets/transparent_square.png" :src="getWMSLegendImageUrl(layer)"
                    @error="onWMSLegendImageError">
                    <template v-slot:placeholder>
                      <div class="d-flex align-center justify-center fill-height">
                        <v-progress-circular :width="4" indeterminate></v-progress-circular>
                      </div>
                    </template>
                  </v-img>
                </a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <div class="text-caption">
                  {{ $t("global.opacity_label") }}
                  : <span class="font-weight-bold">{{ wmsOpacityValText }}</span>
                </div>
              </v-col>
              <v-col class="py-2 px-4" cols="12">
                <v-slider v-model="opacityValue" max="100" min="0"></v-slider>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-else class="d-flex flex-row align-center justify-space-between">
          <div class="subtitle-2">{{ $t('global.layer_default_style') }}</div>
          <div class="style-preview">
            <StylePreview :layertype="layer.layer.layertype.geometry_type" :layerstyle="layer.layer.style" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import StylePreview from "@/components/Layers/StylePreview";
import debounce from "lodash/debounce";
import { EventBus } from "@/helpers/event-bus";

export default {
  props: ["layer"],
  components: {
    StylePreview,
  },
  data() {
    return {
      opacityValue: 100,
      showLegend: true,
    };
  },
  watch: {
    opacityValue(val) {
      this.updateWmsOpacity(val);
    },
  },
  computed: {
    package_id() {
      return this.$route.params.id;
    },
    wmsOpacityValText() {
      return `${this.opacityValue}%`;
    },
    map() {
      return this.$store.state.layers.mapReference;
    },
    hasStyleMapsApplied() {
      return !!this?.layer?.layer?.default_style_map_id && this?.layer?.layerdata;
    },
    layerName() {
      return this.layer.layer.layertype.name;
    },
    layerType() {
      return this.layer.layer.layertype.geometry_type;
    },
  },
  methods: {
    updateWmsOpacity: debounce(function (val) {
      EventBus.$emit("setWmsOpacity", { layer: this.layer, opacity: val });
    }, 50),

    getWMSLegendImageUrl(layer) {
      return `/api/v1/packages/${this.package_id}/layers/${layer.id}/legend`;
    },
    onWMSLegendImageError() {
      this.showLegend = false;
    },
  },
};
</script>
<style scoped>
.style-preview {
  width: 24px;
  height: 24px;
}
</style>
