import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// Icons used
import "@mdi/font/css/materialdesignicons.css";

// fonts used
import "roboto-fontface/css/roboto/roboto-fontface.css";

import theme from '@/plugins/theme';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: theme,
  },
  icons: {
    iconfont: "mdi",
  },
});
