<template>
  <div class="d-flex flex-row align-center">
    <span class="body-2" style="width: 300px"> {{ label }} </span>
    <v-btn text plain x-small width="10px" class="pa-5" @click="sub()">
      <v-icon small>mdi-minus</v-icon>
    </v-btn>
    <v-text-field v-model="inputNumber" number :placeholder="label" outlined dense
      type="number" hide-details single-line hide-spin-buttons :maxlength="maxlength">
      ></v-text-field>
    <v-btn text plain x-small width="10px" class="pa-5" @click="add()">
      <v-icon small>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: [
    "value",
    "label",
    "incrementBy",
    "min",
    "max",
    "maxlength",
  ],
  computed: {
    inputNumber: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  },
  methods: {
    add() {
      if (parseInt(this.inputNumber) == this.max) {
        this.inputNumber = parseInt(this.max);
      } else {
        this.inputNumber =
          parseInt(this.inputNumber) + parseInt(this.incrementBy);
      }
    },
    sub() {
      if (parseInt(this.inputNumber) == this.min) {
        this.inputNumber = parseInt(this.min);
      } else {
        this.inputNumber =
          parseInt(this.inputNumber) - parseInt(this.incrementBy);
      }
    },
  },
};
</script>
