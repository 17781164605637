import configs from "@/helpers/configs";
import axios from "axios";
import { sharedHelpers } from "@/api_services/utils/shared-helpers";

export const packagesService = {
  getPackages,
  getPackage,
};

async function getPackages() {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {},
  };

  const res = await axios.get(
    configs.getApiUrl() + "/v1/packages",
    requestOptions
  );
  return res;
}

async function getPackage(id) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
  };
  const res = await axios.get(
    `${configs.getApiUrl()}/v1/share/packages/${id}`,
    requestOptions
  );
  return res;
}
