import configs from "@/helpers/configs";
import axios from "axios";
import {
  sharedHelpers
} from "@/api_services/utils/shared-helpers";

export const featuresService = {
  search,
  getDetails,
  searchInLayer
};

async function searchInLayer(pid, term, bbox) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {},
  };
  const res = await axios.post(
    configs.getApiUrl() + `/v1/packages/${pid}/features/search`, {
    term: term,
    bbox: bbox
  },
    requestOptions
  );
  return res;
}


async function search(pid, term, bbox, active_layers) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {},
  };
  var bounds;
  var active;
  if (bbox) {
    bounds =
      `${bbox._ne.lng},${bbox._ne.lat},${bbox._sw.lng},${bbox._sw.lat}`;
  } else {
    bounds = null;
  }
  if (active_layers) {
    active = active_layers.map(e => e.id);
  } else {
    active = null;
  }

  const res = await axios.post(
    configs.getApiUrl() + `/v1/packages/${pid}/features/search`, {
    term: term,
    bbox: bounds,
    active_layers: active
  },
    requestOptions
  );
  return res;
}

async function getDetails(pid, fid) {
  var requestOptions = {
    method: "GET",
    headers: sharedHelpers.getRequestHeaders(),
    params: {},
  };
  const res = await axios.get(
    configs.getApiUrl() + `/v1/packages/${pid}/features/${fid}/details`,
    requestOptions
  );
  return res;
}