import { settingsService } from "@/api_services/settings.service";
export default {
  namespaced: true,
  state: {
    loading: true,
    settingsInfo: null,
    shareableKey: null,
  },
  getters: {},
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setSettingsInfo(state, settingsInfo) {
      state.settingsInfo = settingsInfo;
    },

    setShareAbleKey(state, key) {
      state.shareableKey = key;
    },
  },
  actions: {
    SET_SHAREABLE_KEY: (context, key) => {
      return new Promise((resolve) => {
        context.commit("setShareAbleKey", key);
        resolve(key);
      });
    },
    GET_SETTINGS_INFO: (context) => {
      return new Promise((resolve) => {
        context.commit("setLoading", true);
        settingsService
          .getSettings()
          .then(({ data }) => {
            context.commit("setSettingsInfo", data);
            context.commit("setLoading", false);
            resolve(data);
          })
          .catch((e) => {
            context.commit("setSettingsInfo", null);
            context.commit("setLoading", false);
            console.error(e);
          });
      });
    },
  },
};
