export default class CustomMapMarkerSVG {
    static HOVERED_STROKE_COLOR = "#6d8717";
    static SELECTED_STROKE_COLOR = "#F48C2F";
    static HOVERED_FILL_OPACITY = "0.7";
    static SELECTED_STROKE_OPACITY = "0.8";
    static SELECTED_STROKE_WIDTH = 9;
    static HOVERED_STROKE_WIDTH = 5;

    static POINT_HOVERED_FILL_COLOR = "#ffb121";
    static POINT_SELECTED_FILL_COLOR = "#F48C2F";

    static buildSvg(style, iconSvg, isNode = false) {
        let ret = {
            hoveredSVG: null,
            unhoveredSVG: null,
            selectedSVG: null
        }
        let condition = isNode ? style.iconShape_node : style.iconShape;
        if (condition == "rectangle-dot") {
            ret.hoveredSVG = this.createSquare(style,
                "hovered",
                isNode
            );
            ret.unhoveredSVG = this.createSquare(
                style,
                "unhovered",
                isNode
            );
            ret.selectedSVG = this.createSquare(
                style,
                "selected",
                isNode
            );
        } else if (condition == "marker") {
            ret.hoveredSVG = this.createMarker(
                style,
                iconSvg,
                "hovered",
                isNode
            );
            ret.unhoveredSVG = this.createMarker(
                style,
                iconSvg,
                "unhovered",
                isNode
            );
            ret.selectedSVG = this.createMarker(
                style,
                iconSvg,
                "selected",
                isNode
            );
        } else if (condition == "doughnut") {
            ret.hoveredSVG = this.createDoughnut(
                style,
                "hovered",
                isNode
            );
            ret.unhoveredSVG = this.createDoughnut(
                style,
                "unhovered",
                isNode
            );
            ret.selectedSVG = this.createDoughnut(
                style,
                "selected",
                isNode
            );
        } else {
            ret.hoveredSVG = this.createCircle(
                style,
                iconSvg,
                "hovered",
                isNode
            );
            ret.unhoveredSVG = this.createCircle(
                style,
                iconSvg,
                "unhovered",
                isNode
            );
            ret.selectedSVG = this.createCircle(
                style,
                iconSvg,
                "selected",
                isNode
            );
        }
        return ret;
    }

    static buildNodeSvgPreview(style, iconSvg) {
        let ret = {
            unhoveredSVG: null,
        }
        if (style.iconShape_node == "rectangle-dot") {
            ret.unhoveredSVG = this.createNode(
                style,
                iconSvg,
                true
            );
        } else if (style.iconShape_node == "marker") {
            ret.unhoveredSVG = this.createNode(
                style,
                iconSvg,
                false
            );
        } else if (style.iconShape_node == "doughnut") {
            ret.unhoveredSVG = this.createNode(
                style,
                iconSvg,
                true
            );

        } else if (style.iconShape_node == "circle") {
            ret.unhoveredSVG = this.createNode(
                style,
                iconSvg,
                false
            );
        }
        return ret;
    }

    static createDoughnut(style, state, isNode = false) {
        let cx, cy, r, w;
        r = isNode ? style.radius_node * 1.2 : style.radius * 1.2
        w = r * 2;
        cx = w / 2;
        cy = w / 2;
        let dashProps = this._makeDashedPropCircle(style, isNode);
        let svg;
        let adaptedStyle = {};

        if (isNode) {
            adaptedStyle.icon = style.icon_node;
            adaptedStyle.iconColor = style.iconColor_node;
            adaptedStyle.color = style.color_node;
            adaptedStyle.fillColor = style.fillColor_node;
            adaptedStyle.fillOpacity = style.fillOpacity_node;
            adaptedStyle.weight = style.border_node == "none" ? 0 : style.weight_node;
            adaptedStyle.radius = style.radius_node;

        } else {
            adaptedStyle.iconColor = style.iconColor;
            adaptedStyle.color = style.color;
            adaptedStyle.fillColor = style.fillColor;
            adaptedStyle.fillOpacity = style.fillOpacity;
            adaptedStyle.weight = style.border == "none" ? 0 : style.weight;
            adaptedStyle.radius = style.radius;
        }

        if (state === "unhovered") {
            svg =
                `
                <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                    <title>Layer 1</title>
                    <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${adaptedStyle.fillOpacity}" r="${r - adaptedStyle.weight}" cx="${cx}" cy="${cy}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${adaptedStyle.fillColor}"/>
                    </g>
                </svg>
            `;

        } else if (state === "hovered") {
            svg =
                `
                <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                    <title>Layer 1</title>
                    <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${this.HOVERED_FILL_OPACITY}" r="${r - adaptedStyle.weight}" cx="${cx}" cy="${cy}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${this.POINT_HOVERED_FILL_COLOR}"/>
                    </g>
                </svg>
            `;
        } else {
            svg =
                `
                <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                    <title>Layer 1</title>
                    <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${adaptedStyle.fillOpacity}" r="${r - adaptedStyle.weight}" cx="${cx}" cy="${cy}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${this.POINT_SELECTED_FILL_COLOR}"/>
                    </g>
                </svg>
            `;

        }
        return svg;
    }


    static createCircle(style, iconSvg, state, isNode = false) {
        let cx, cy, r, w;
        r = isNode ? style.radius_node * 1.2 : style.radius * 1.2
        w = r * 2;
        cx = w / 2;
        cy = w / 2;
        let dashProps = this._makeDashedPropCircle(style, isNode);
        let svg;
        let adaptedStyle = {};

        if (isNode) {
            adaptedStyle.icon = style.icon_node;
            adaptedStyle.iconColor = style.iconColor_node;
            adaptedStyle.color = style.color_node;
            adaptedStyle.fillColor = style.fillColor_node;
            adaptedStyle.fillOpacity = style.fillOpacity_node;
            adaptedStyle.opacity = style.opacity_node;
            adaptedStyle.weight = style.weight_node;
            adaptedStyle.radius = style.radius_node;
        } else {
            adaptedStyle.icon = style.icon;
            adaptedStyle.iconColor = style.iconColor;
            adaptedStyle.color = style.color;
            adaptedStyle.fillColor = style.fillColor;
            adaptedStyle.fillOpacity = style.fillOpacity;
            adaptedStyle.opacity = style.opacity;
            adaptedStyle.weight = style.border == "none" ? 0 : style.weight;
            adaptedStyle.radius = style.radius;
        }

        if (iconSvg == null) {
            if (state === "unhovered") {
                svg =
                    `
                    <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                        <title>Layer 1</title>
                        <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${adaptedStyle.fillOpacity}" r="${adaptedStyle.radius - adaptedStyle.weight}" cx="${adaptedStyle.radius}" cy="${adaptedStyle.radius}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${adaptedStyle.fillColor}"/>
                            <g transform="scale(0.5, 0.5) translate(${adaptedStyle.radius},${adaptedStyle.radius})" opacity="${adaptedStyle.opacity}" fill="${adaptedStyle.fillColor}" id="svg_15">
                                <image width="${w}" height="${w}" xlink:href="${adaptedStyle.icon}"/>
                            </g>
                        </g>
                    </svg>
                `;

            } else if (state === "hovered") {
                svg =
                    `
                    <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                        <title>Layer 1</title>
                        <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${this.HOVERED_FILL_OPACITY}" r="${adaptedStyle.radius - adaptedStyle.weight}" cx="${adaptedStyle.radius}" cy="${adaptedStyle.radius}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${this.POINT_HOVERED_FILL_COLOR}"/>
                            <g transform="scale(0.5, 0.5) translate(${adaptedStyle.radius},${adaptedStyle.radius})" opacity="${adaptedStyle.opacity}" fill="${adaptedStyle.fillColor}" id="svg_15">
                                <image width="${w}" height="${w}" xlink:href="${adaptedStyle.icon}"/>
                            </g>
                        </g>
                    </svg>
                `;
            } else {
                svg =
                    `
                    <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                        <title>Layer 1</title>
                        <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${this.HOVERED_FILL_OPACITY}" r="${adaptedStyle.radius - adaptedStyle.weight}" cx="${adaptedStyle.radius}" cy="${adaptedStyle.radius}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${this.POINT_SELECTED_FILL_COLOR}"/>
                            <g transform="scale(0.5, 0.5) translate(${adaptedStyle.radius},${adaptedStyle.radius})" opacity="${adaptedStyle.opacity}" fill="${adaptedStyle.fillColor}" id="svg_15">
                                <image width="${w}" height="${w}" xlink:href="${adaptedStyle.icon}"/>
                            </g>
                        </g>
                    </svg>
                `;

            }
        } else {

            if (state === "unhovered") {
                svg =
                    `
                    <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                        <title>Layer 1</title>
                        <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${adaptedStyle.fillOpacity}" r="${r - adaptedStyle.weight}" cx="${cx}" cy="${cy}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${adaptedStyle.fillColor}"/>
                            <g transform="scale(0.5, 0.5) translate(${cx},${cy})" opacity="${adaptedStyle.opacity}" fill="${adaptedStyle.iconColor}" id="svg_15">
                                ${iconSvg}
                            </g>
                        </g>
                    </svg>
                `;
            } else if (state === "hovered") {
                svg =
                    `
                    <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg">
                        <g>
                        <title>Layer 1</title>
                        <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${this.HOVERED_FILL_OPACITY}" r="${r - adaptedStyle.weight}" cx="${cx}" cy="${cy}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${this.POINT_HOVERED_FILL_COLOR}"/>
                            <g transform="scale(0.5, 0.5) translate(${cx},${cy})" opacity="${adaptedStyle.opacity}" fill="${adaptedStyle.iconColor}" id="svg_15">
                                ${iconSvg}
                            </g>
                        </g>
                    </svg>
                `;
            } else {
                svg =
                    `
                    <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg">
                        <g>
                        <title>Layer 1</title>
                        <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${this.HOVERED_FILL_OPACITY}" r="${r - adaptedStyle.weight}" cx="${cx}" cy="${cy}" id="svg_8" stroke-width="${adaptedStyle.weight}" fill="${this.POINT_SELECTED_FILL_COLOR}"/>
                            <g transform="scale(0.5, 0.5) translate(${cx},${cy})" opacity="${adaptedStyle.opacity}" fill="${adaptedStyle.iconColor}" id="svg_15">
                                ${iconSvg}
                            </g>
                        </g>
                    </svg>
                `;

            }
        }
        return svg;
    }

    static createNode(style, iconSvg, noIcon) {
        let w = style.radius * 2;
        let dashProps = this._makeDashedPropCircle(style, true);
        let lineDashProps = this._makeDashedPropLine(style, true);
        let svg;
        let adaptedStyle = {};

        adaptedStyle.icon = style.icon_node;
        adaptedStyle.iconColor = style.iconColor_node;
        adaptedStyle.color = style.color_node;
        adaptedStyle.fillColor = style.fillColor_node;
        adaptedStyle.fillOpacity = style.fillOpacity_node;
        adaptedStyle.weight = style.weight_node;
        adaptedStyle.radius = style.radius_node;

        //For nodes that are a square or doughnut there are no icons or images
        if (noIcon) {
            if (style.iconShape_node === "doughnut") {
                svg =
                    `
                <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                    <title>Layer 1</title>
                    <line stroke-width="4" stroke-dasharray="${lineDashProps.strokeDashArray}" stroke="${style.color_link}" stroke-linecap="${lineDashProps.strokeDashLineCap}" stroke-linejoin="undefined" id="svg_3" y2="0" x2="32.18182" y1="32" x1="-0.18182" fill="${style.color_link}"/>
                    <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${adaptedStyle.fillOpacity}" cy="16" cx="16" r="11.72727" id="svg_8" stroke-width="4" fill="${adaptedStyle.fillColor}"/>
                    </g>
                </svg>
                `;
                return svg;
            } else if (style.iconShape_node === "rectangle-dot") {
                let prop = {
                    strokeDashArray: "",
                    strokeDashLineCap: ""
                }
                if (style.border_node == "solid") {
                    prop.strokeDashArray = "";
                    prop.strokeDashLineCap = "";

                } else if (style.border_node == "dotted") {
                    prop.strokeDashArray = "15, 30";
                    prop.strokeDashLineCap = "round";

                } else if (style.border_node == "dashed") {
                    prop.strokeDashArray = "15, 30";
                    prop.strokeDashLineCap = "square";
                }
                svg =
                    `
                <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                <title>Layer 1</title>
                <line stroke-width="20" stroke-dasharray="${prop.strokeDashArray}" stroke="${style.color_link}" stroke-linecap="${prop.strokeDashLineCap}" stroke-linejoin="undefined" id="svg_2" y2="0.55592" x2="198.88853" y1="199.44408" x1="1.11147" fill="${style.color_link}"/>
                <rect stroke="${adaptedStyle.color}" stroke-dasharray="${prop.strokeDashArray}" x="34.50229" y="33.94673" fill="${adaptedStyle.fillColor}" stroke-width="20" width="130.99542" height="130.99542" id="svg_11" stroke-linecap="${prop.strokeDashLineCap}"/>
                </svg>
                `;
                return svg;
            }
        }
        if (iconSvg == null) {
            if (style.iconShape_node === "circle") {
                svg =
                    `
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g>
                 <title>Layer 1</title>
                 <line stroke-width="${style.weight_link}" stroke-dasharray="${lineDashProps.strokeDashArray}" stroke="${style.color_link}" stroke-linecap="${lineDashProps.strokeDashLineCap}" stroke-linejoin="undefined" id="svg_3" y2="0" x2="32.18182" y1="32" x1="-0.18182" fill="${style.color_link}"/>
                 <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${adaptedStyle.fillOpacity}" stroke-width="${adaptedStyle.weight}" fill="${adaptedStyle.fillColor}" id="svg_8" cy="16" cx="16" r="11.72727"/>
                 <g stroke="null" id="svg_15" fill="${style.fillColor_node}" transform="matrix(0.430303 0 0 0.430303 8.13939 8)">
                  <svg y="2.96188" x="2.62716" stroke="null" id="svg_1" xmlns="http://www.w3.org/2000/svg">
                    <image width="${w}" height="${w}" xlink:href="${adaptedStyle.icon}"/>
                  </svg>
                 </g>
                </g>
               </svg>
                `;
                return svg;
            } else if (style.iconShape_node === "marker") {
                svg =
                    `
                <svg width="${w}" height="${w}" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <line stroke-dasharray="${lineDashProps.strokeDashArray}" stroke-width="6" stroke-linecap="${lineDashProps.strokeDashLineCap}" stroke-linejoin="undefined" id="svg_5" y2="0.34233" x2="69.93137" y1="69.65713" x1="-0.06836" stroke="${style.color_link}" fill="none"/>
                    <g>
                        <path stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" stroke-width="${adaptedStyle.weight}" fill="${adaptedStyle.fillColor}" fill-opacity="${adaptedStyle.fillOpacity}" id="svg_1" d="m35,5.59298a20.58491,20.58491 0 0 0 -20.58491,20.58491c0,15.43868 20.58491,38.22912 20.58491,38.22912c0,0 20.58491,-22.79044 20.58491,-38.22912a20.58491,20.58491 0 0 0 -20.58491,-20.58491z"/>
                        <g transform="scale(0.4, 0.4) translate(52, 35)" opacity="1" fill="${adaptedStyle.fillColor}" id="svg_15">
                            <image width="70" height="70" xlink:href="${adaptedStyle.icon}"/>
                         </g>
                    </g> 
                </svg>
                `;
                return svg;
            }
        } else {
            if (style.iconShape_node === "circle") {
                svg =
                    `
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                <g>
                 <title>Layer 1</title>
                 <line stroke-width="${style.weight_link}" stroke-dasharray="${lineDashProps.strokeDashArray}" stroke="${style.color_link}" stroke-linecap="${lineDashProps.strokeDashLineCap}" stroke-linejoin="undefined" id="svg_3" y2="0" x2="32.18182" y1="32" x1="-0.18182" fill="${style.color_link}"/>
                 <circle stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" fill-opacity="${adaptedStyle.fillOpacity}" stroke-width="${adaptedStyle.weight}" fill="${adaptedStyle.fillColor}" id="svg_8" cy="16" cx="16" r="11.72727"/>
                 <g stroke="null" id="svg_15" fill="${style.iconColor_node}" transform="matrix(0.430303 0 0 0.430303 8.13939 8)">
                  <svg y="2.96188" x="2.62716" stroke="null" id="svg_1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                   ${iconSvg}
                  </svg>
                 </g>
                </g>
               </svg>
                `;
                return svg;
            } else if (style.iconShape_node === "marker") {
                svg =
                    `
                <svg width="${w}" height="${w}" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <line stroke-dasharray="${lineDashProps.strokeDashArray}" stroke-width="6" stroke-linecap="${lineDashProps.strokeDashLineCap}" stroke-linejoin="undefined" id="svg_5" y2="0.34233" x2="69.93137" y1="69.65713" x1="-0.06836" stroke="${style.color_link}" fill="none"/>
                    <g>
                        <path stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" stroke-width="${adaptedStyle.weight}" fill="${adaptedStyle.fillColor}" fill-opacity="${style.fillOpacity}" id="svg_1" d="m35,5.59298a20.58491,20.58491 0 0 0 -20.58491,20.58491c0,15.43868 20.58491,38.22912 20.58491,38.22912c0,0 20.58491,-22.79044 20.58491,-38.22912a20.58491,20.58491 0 0 0 -20.58491,-20.58491z"/>
                        <g transform="scale(0.4, 0.4) translate(52, 35)" opacity="${style.fillOpacity}" fill="${adaptedStyle.iconColor}" id="svg_15">
                            ${iconSvg}
                         </g>
                    </g> 
                </svg>
                `;
                return svg;
            }
        }
    }

    static createSquare(style, state, isNode = false) {
        let w = style.radius * 2;
        w = isNode ? style.radius_node * 2 : style.radius * 2
        let dashProps = this._makeDashedPropSquare(style, isNode);
        let svg;
        let adaptedStyle = {};

        if (isNode) {
            adaptedStyle.color = style.color_node;
            adaptedStyle.fillColor = style.fillColor_node;
            adaptedStyle.fillOpacity = style.fillOpacity_node;
            adaptedStyle.weight = style.border_node == "none" ? 0 : style.weight_node;
        } else {
            adaptedStyle.color = style.color;
            adaptedStyle.fillColor = style.fillColor;
            adaptedStyle.fillOpacity = style.fillOpacity;
            adaptedStyle.weight = style.border == "none" ? 0 : style.weight;
        }

        if (state === "unhovered") {
            svg =
                `
                <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <rect stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" id="svg_11" height="${w}" width="${w}" y="0" x="0" stroke-width="${adaptedStyle.weight}" fill="${adaptedStyle.fillColor}" fill-opacity="${adaptedStyle.fillOpacity}"/>
                    </g>
                </svg>
                `;


        } else if (state === "hovered") {
            svg =
                `
            <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <rect stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" id="svg_11" height="${w}" width="${w}" y="0" x="0" stroke-width="${adaptedStyle.weight}" fill="${this.POINT_SELECTED_FILL_COLOR}" fill-opacity="${this.HOVERED_FILL_OPACITY}"/>
                </g>
            </svg>
            `;
        } else {
            svg =
                `
            <svg width="${w}" height="${w}" viewBox="0 0 ${w} ${w}" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <rect stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${adaptedStyle.color}" id="svg_11" height="${w}" width="${w}" y="0" x="0" stroke-width="${adaptedStyle.weight}" fill="${this.POINT_SELECTED_FILL_COLOR}" fill-opacity="${adaptedStyle.fillOpacity}"/>
                </g>
            </svg>
            `;
        }
        return svg;
    }


    static createMarker(style, iconSvg, state, isNode = false) {
        let w = style.radius * 3;
        let h = style.radius * 3;
        let dashProps = this._makeDashedPropMarker(style, isNode);
        let svg;
        let styleCopy = Object.assign({}, style);
        if (isNode) {
            styleCopy.icon = style.icon_node;
            styleCopy.iconColor = styleCopy.iconColor_node;
            styleCopy.color = style.color_node;
            styleCopy.fillColor = style.fillColor_node;
            styleCopy.fillOpacity = style.fillOpacity_node;
            styleCopy.weight = style.border_node == "none" ? 0 : style.weight_node;
        } else {
            styleCopy.weight = style.border == "none" ? 0 : style.weight;
        }

        if (iconSvg == null) {
            if (state === "unhovered") {
                svg =
                    `
                    <svg width="${w}" height="${h}" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                            <path stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${styleCopy.color}" stroke-width="${styleCopy.weight}" fill="${styleCopy.fillColor}" fill-opacity="${styleCopy.fillOpacity}" id="svg_1" d="m35,5.59298a20.58491,20.58491 0 0 0 -20.58491,20.58491c0,15.43868 20.58491,38.22912 20.58491,38.22912c0,0 20.58491,-22.79044 20.58491,-38.22912a20.58491,20.58491 0 0 0 -20.58491,-20.58491z"/>
                            <g transform="scale(0.4, 0.4) translate(52, 35)" opacity="1" fill="${styleCopy.fillColor}" id="svg_15">
                                <image width="70" height="70" xlink:href="${styleCopy.icon}"/>
                             </g>
                        </g> 
                    </svg>
                    `;
            } else if (state === "hovered") {
                svg =
                    `
                    <svg width="${w}" height="${h}" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                        <path stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${styleCopy.color}" stroke-width="${styleCopy.weight}" fill="${this.POINT_HOVERED_FILL_COLOR}" fill-opacity="${this.HOVERED_FILL_OPACITY}" id="svg_1" d="m35,5.59298a20.58491,20.58491 0 0 0 -20.58491,20.58491c0,15.43868 20.58491,38.22912 20.58491,38.22912c0,0 20.58491,-22.79044 20.58491,-38.22912a20.58491,20.58491 0 0 0 -20.58491,-20.58491z"/>
                        <g transform="scale(0.4, 0.4) translate(52, 35)" opacity="1" fill="${styleCopy.fillColor}" id="svg_15">
                            <image width="70" height="70" xlink:href="${styleCopy.icon}"/>
                         </g>
                    </g> 
                </svg>
                `;
            } else {
                svg =
                    `
                    <svg width="${w}" height="${h}" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                        <path stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${styleCopy.color}" stroke-width="${styleCopy.weight}" fill="${this.POINT_SELECTED_FILL_COLOR}" fill-opacity="${this.HOVERED_FILL_OPACITY}" id="svg_1" d="m35,5.59298a20.58491,20.58491 0 0 0 -20.58491,20.58491c0,15.43868 20.58491,38.22912 20.58491,38.22912c0,0 20.58491,-22.79044 20.58491,-38.22912a20.58491,20.58491 0 0 0 -20.58491,-20.58491z"/>
                        <g transform="scale(0.4, 0.4) translate(52, 35)" opacity="1" fill="${styleCopy.fillColor}" id="svg_15">
                            <image width="70" height="70" xlink:href="${styleCopy.icon}"/>
                         </g>
                    </g> 
                </svg>
                `;
            }
        } else {
            if (state === "unhovered") {
                svg =
                    `
                    <svg width="${w}" height="${h}" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${styleCopy.color}" stroke-width="${styleCopy.weight}" fill="${styleCopy.fillColor}" fill-opacity="${styleCopy.fillOpacity}" id="svg_1" d="m35,5.59298a20.58491,20.58491 0 0 0 -20.58491,20.58491c0,15.43868 20.58491,38.22912 20.58491,38.22912c0,0 20.58491,-22.79044 20.58491,-38.22912a20.58491,20.58491 0 0 0 -20.58491,-20.58491z"/>
                            <g transform="scale(0.4, 0.4) translate(52, 35)" opacity="${styleCopy.fillOpacity}" fill="${styleCopy.iconColor}" id="svg_15">
                                 ${iconSvg}
                             </g>
                        </g> 
                    </svg>
                    `;

            } else if (state === "hovered") {
                svg =
                    `
                    <svg width="${w}" height="${h}" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${styleCopy.color}" stroke-width="${styleCopy.weight}" fill="${this.POINT_HOVERED_FILL_COLOR}" fill-opacity="${this.HOVERED_FILL_OPACITY}" id="svg_1" d="m35,5.59298a20.58491,20.58491 0 0 0 -20.58491,20.58491c0,15.43868 20.58491,38.22912 20.58491,38.22912c0,0 20.58491,-22.79044 20.58491,-38.22912a20.58491,20.58491 0 0 0 -20.58491,-20.58491z"/>
                        <g transform="scale(0.4, 0.4) translate(52, 35)" opacity="${styleCopy.fillOpacity}" fill="${styleCopy.iconColor}" id="svg_15">
                             ${iconSvg}
                         </g>
                    </g> 
                </svg>
                `;
            } else {
                svg =
                    `
                    <svg width="${w}" height="${h}" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path stroke-dasharray="${dashProps.strokeDashArray}" stroke-linecap="${dashProps.strokeDashLineCap}" stroke="${styleCopy.color}" stroke-width="${styleCopy.weight}" fill="${this.POINT_SELECTED_FILL_COLOR}" fill-opacity="${this.HOVERED_FILL_OPACITY}" id="svg_1" d="m35,5.59298a20.58491,20.58491 0 0 0 -20.58491,20.58491c0,15.43868 20.58491,38.22912 20.58491,38.22912c0,0 20.58491,-22.79044 20.58491,-38.22912a20.58491,20.58491 0 0 0 -20.58491,-20.58491z"/>
                        <g transform="scale(0.4, 0.4) translate(52, 35)" opacity="${styleCopy.fillOpacity}" fill="${styleCopy.iconColor}" id="svg_15">
                             ${iconSvg}
                         </g>
                    </g> 
                </svg>
                `;
            }
        }
        return svg;
    }

    static _makeDashedPropLineString(style) {
        let prop = {
            strokeDashArray: "",
            strokeDashLineCap: ""
        }
        if (style.border == "solid") {
            prop.strokeDashArray = "";
            prop.strokeDashLineCap = "";

        } else if (style.border == "dotted") {
            prop.strokeDashArray = "0.8, 10";
            prop.strokeDashLineCap = "round";

        } else if (style.border == "dashed") {
            prop.strokeDashArray = "0.8, 10";
            prop.strokeDashLineCap = "square";
        }
        return prop;
    }

    static _makeDashedPropLine(style, isNode = false) {
        let prop = {
            strokeDashArray: "",
            strokeDashLineCap: ""
        }
        let condition = isNode ? style.border_link : style.border;
        if (condition == "solid") {
            prop.strokeDashArray = "";
            prop.strokeDashLineCap = "";

        } else if (condition == "dotted") {
            prop.strokeDashArray = "4, 10";
            prop.strokeDashLineCap = "round";

        } else if (condition == "dashed") {
            prop.strokeDashArray = "0.8, 8";
            prop.strokeDashLineCap = "square";
        }
        return prop;
    }

    static _makeDashedPropCircle(style, isNode = false) {
        let prop = {
            strokeDashArray: "",
            strokeDashLineCap: ""
        }
        let condition = isNode ? style.border_node : style.border;
        if (condition == "solid") {
            prop.strokeDashArray = "";
            prop.strokeDashLineCap = "";

        } else if (condition == "dotted") {
            prop.strokeDashArray = "2, 8";
            prop.strokeDashLineCap = "round";

        } else if (condition == "dashed") {
            prop.strokeDashArray = "5, 10";
            prop.strokeDashLineCap = "square";
        }
        return prop;
    }

    static _makeDashedPropSquare(style, isNode = false) {
        let prop = {
            strokeDashArray: "",
            strokeDashLineCap: ""
        }
        let condition = isNode ? style.border_node : style.border;
        if (condition == "solid") {
            prop.strokeDashArray = "";
            prop.strokeDashLineCap = "";

        } else if (condition == "dotted") {
            prop.strokeDashArray = "0.2, 8";
            prop.strokeDashLineCap = "round";

        } else if (condition == "dashed") {
            prop.strokeDashArray = "2, 8";
            prop.strokeDashLineCap = "square";
        }
        return prop;
    }

    static _makeDashedPropMarker(style, isNode = false) {
        let prop = {
            strokeDashArray: "",
            strokeDashLineCap: ""
        }
        let condition = isNode ? style.border_node : style.border;
        if (condition == "solid") {
            prop.strokeDashArray = "";
            prop.strokeDashLineCap = "";

        } else if (condition == "dotted") {
            prop.strokeDashArray = "2, 8";
            prop.strokeDashLineCap = "round";

        } else if (condition == "dashed") {
            prop.strokeDashArray = "4, 10";
            prop.strokeDashLineCap = "square";
        }
        return prop;
    }

    static async fetchRawSvgFaIcon(url) {
        const response = await fetch(url);
        let text;
        if (response.ok) {
            text = await response.text();
        } else {
            text = response.ok;
        }
        return text;
    }

    static buildUrls(style, isNode = false) {
        let urls = [];
        let iconFromStyle = isNode ? style.icon_node : style.icon;
        let icon = iconFromStyle.split(" ")[1].replace("fa-", "");
        urls.push(
            `https://raw.githubusercontent.com/FortAwesome/Font-Awesome/5.x/svgs/solid/${icon}.svg`
        );
        urls.push(
            `https://raw.githubusercontent.com/FortAwesome/Font-Awesome/5.x/svgs/regular/${icon}.svg`
        );
        urls.push(
            `https://raw.githubusercontent.com/FortAwesome/Font-Awesome/5.x/svgs/brands/${icon}.svg`
        );
        return urls;
    }

}