export default {
    namespaced: true,
    state: {
        platformName: "3Maps",
        platformColor: "",
        platformIcon: "",
        currentPackageId: null,
        navbarOpen: false,
        
        searchOpen: false,
        searchTerm: "",
        searchMode: "everywhere",


        featureDetailsOpen: false,
        helpDialogOpen: false,
        uploadExternalDialogOpen: false,
    },
    mutations: {
        toggleNavbar(state) {
            state.navbarOpen = !state.navbarOpen;
        },

        setNavbarOpen(state, isOpen) {
            state.navbarOpen = isOpen;
        },

        setSearchOpen(state, isOpen) {
            state.searchOpen = isOpen;
        },

        setFeatureDetailsOpen(state, isOpen) {
            state.featureDetailsOpen = isOpen;
        },

        setSearchTerm(state, term) {
            state.searchTerm = term;
            if (term) {
                state.searchOpen = true;
            }
        },

        setCurrentPackageId(state, id) {
            state.currentPackageId = id;
        },

        setHelpDialogOpen(state, isOpen) {
            state.helpDialogOpen = isOpen;
        },

        setSearchMode(state, mode) {
            state.searchMode = mode;
        },

        setUploadExternalDialogOpen(state, isOpen) {
            state.uploadExternalDialogOpen = isOpen;
        },
    },
    actions: {
        TOGGLE_NAVBAR(context) {
            context.commit("toggleNavbar");
        },

        SET_NAVBAR_OPEN(context, isOpen) {
            context.commit("setNavbarOpen", isOpen);
        },

        SET_CURRENT_PACKAGE_ID(context, id) {
            context.commit("setCurrentPackageId", id);
        },

        SET_SEARCH_OPEN(context, isOpen) {
            context.commit("setSearchOpen", isOpen);
        },

        SET_FEATURE_DETAILS_OPEN(context, isOpen) {
            context.commit("setFeatureDetailsOpen", isOpen);
        },

        SET_SEARCH_TERM(context, {
            package_id,
            term,
            bbox,
            active_layers
        }) {
            context.commit("setSearchTerm", term);
            context.dispatch("features/SEARCH", {
                package_id,
                term,
                bbox,
                active_layers
            }, {
                root: true
            });
        },

        RESET_STATE(context) {
            context.commit("setSearchTerm", "");
            context.commit("setFeatureDetailsOpen", false);
            context.commit("setSearchOpen", false);
            context.commit("setNavbarOpen", false);
            context.commit("setSearchMode", "everywhere");
        },

        SET_HELP_DIALOG_OPEN(context, isOpen) {
            context.commit("setHelpDialogOpen", isOpen);
        },

        SET_SEARCH_MODE(context, mode) {
            context.commit("setSearchMode", mode);
        },
        
        OPEN_UPLOAD_EXTERNAL_LAYER(context) {
            context.commit("setUploadExternalDialogOpen", true);
        },

        SET_UPLOAD_EXTERNAL_LAYER_OPEN(context, isOpen) {
            context.commit("setUploadExternalDialogOpen", isOpen);
        },
    },
};